import React from 'react';
import {Skeleton} from "@/components/ui/skeleton";

const LatestSkeleton = () => {
  return (
    <div>
      <div>
        <Skeleton className="w-full h-[440px] mb-8" />
      </div>
      <div className="flex flex-col md:flex-row gap-8">
        <div className="basis-1/3 flex flex-col space-y-3">
          <Skeleton className="h-[350px] w-full"/>
          <div className="space-y-1">
            <Skeleton className="h-[24px] w-[250px]"/>
            <Skeleton className="h-[88px] w-full"/>
          </div>
        </div>

        <div className="basis-1/3 flex flex-col space-y-3">
          <Skeleton className="h-[350px] w-full"/>
          <div className="space-y-1">
            <Skeleton className="h-[24px] w-[250px]"/>
            <Skeleton className="h-[88px] w-full"/>
          </div>
        </div>

        <div className="basis-1/3 flex flex-col space-y-3">
          <Skeleton className="h-[350px] w-full"/>
          <div className="space-y-1">
            <Skeleton className="h-[24px] w-[250px]"/>
            <Skeleton className="h-[88px] w-full"/>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LatestSkeleton;