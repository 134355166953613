import {ReactElement} from "react";
import Link from "next/link";
import {formatDatetime} from "@/lib/utils";
import AdaptiveSeparator from "@/components/ui/adaptive-separator";

/**
 * Récupère le flux RSS d'Europe 1 et renvoie le titre, le lien et la date des 3 articles les plus récents
 * @constructor
 */
const ViewFeed = ({item, isLast}): ReactElement => {
    return (
        <>
            <Link href={item.link} target="_blank" className="flex flex-col justify-between flex-1 hover:underline py-2">
                <p className="text-xl text-foreground-dark line-clamp-2">{item.title}</p>
                <p className="text-sm text-gray-400 mt-4">{formatDatetime(item.pubDate)}</p>
            </Link>
            {/* Ajoute un séparateur si l'élément suivant existe */}
            {!isLast && <AdaptiveSeparator defaultOrientation="vertical" />}
        </>
    );
}
export default ViewFeed;
