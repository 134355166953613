import {ReactElement} from "react";
import Link from "next/link";
import {Button} from "@/components/ui/button";


/**
 * Bannière des avis de décès
 * @constructor
 */
const AvisDecesBannerComponent = (): ReactElement => {
    return (
        <>
            <div className="p-5 w-full md:min-h-[255px] min-h-[320px] avis-deces-bg">
                <div className="flex md:inline-flex flex-col gap-3">
                    <div className="text-center md:text-left text-white text-[32px]">Avis de décès</div>
                    <Button variant='secondary' asChild>
                        <Link href='/avis'>Voir les avis de décès</Link>
                    </Button>
                </div>
            </div>
        </>
    )
}
export default AvisDecesBannerComponent;