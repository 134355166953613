import {ReactElement} from "react";
import {DrupalNode} from "next-drupal";
import {Button} from "@/components/ui/button";
import Link from "next/link";
import {NodeDefaultTeaser} from "@/components/nodeTypes";

export default function ArticlesFeed({articles, ...props}): ReactElement {

  return (
    <div {...props}>
      <div className="py-5 w-full min-h-[255px] mb-8">
        <h2 className="text-[32px] text-foreground-dark mb-8">Le fil info</h2>

        <div className="grid md:grid-cols-2 gap-8">
          {articles && articles.map((article: DrupalNode) => <NodeDefaultTeaser className='group relative'
                                                                                              key={article.id}
                                                                                              node={article}/>)}
        </div>

        <div className="mt-8 flex max-md:flex-col justify-center">
          <Button className="justify-self-center" asChild>
            <Link href="/articles">Voir tous les articles</Link>
          </Button>
        </div>
      </div>
    </div>
  )
}
