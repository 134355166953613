import {ReactElement, useEffect, useState, Fragment} from "react";
import Link from "next/link";
import {Button} from "@/components/ui/button";
import {EuropeItemProps} from "@/components/europe1/types";
import {Europe1ViewFeed} from "@/components/europe1/";

const europe1RssFeed: string = "https://www.europe1.fr/rss.xml";

/**
 * Affichage du flux RSS d'Europe 1
 *
 * @constructor
 */
const RssFeed = (): ReactElement => {
    const [feedItems, setFeedItems] = useState<any>([]);

    useEffect(() => {
        const fetchFeed = async () => {
            try {
                const response: Response = await fetch(`/api/rss?url=${encodeURIComponent(europe1RssFeed)}`);
                const data = await response.json();

                // Renvoie uniquement les 3 éléments les plus récent
                setFeedItems(data.slice(0, 3));

            } catch (error) {
                console.error('Erreur lors de la récupération du flux RSS :', error);
            }
        };
        fetchFeed();
        return () => {setFeedItems([])};
    }, []);

    // Vue du composant à retourner
    return (
        <>
            <div className="py-6 my-6 gap-4 max-md:bg-lightBlue max-md:p-6 -mx-4 md:mx-0">
                <h2 className="text-4xl text-foreground-dark">Europe 1</h2>
                <div className="flex md:flex-row flex-col items-stretch py-6 gap-4">
                    {feedItems.map((item: EuropeItemProps, index) => (
                        <Europe1ViewFeed item={item} key={index} isLast={index == feedItems.length - 1}/>
                    ))}
                </div>
                <div className="flex max-md:flex-col justify-center">
                    <Button className="justify-self-center" asChild>
                        <Link href="https://www.europe1.fr" target="_blank">Voir le site Europe 1</Link>
                    </Button>
                </div>
            </div>
        </>
    );
}

export default RssFeed;
