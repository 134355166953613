import {ReactElement} from "react";
import Link from "next/link";
import {Button} from "@/components/ui/button";


const HistoireRadioBannerComponent = (): ReactElement => {
    return (
        <>
            <div className="p-5 w-full min-h-[255px] banner-histoire-radio-bg">
                <div className="flex md:inline-flex flex-col gap-3">
                    <div className="text-center md:text-left text-white text-[32px]">L&apos;histoire de la radio</div>
                    <Button variant='secondary' asChild>
                        <Link href='/histoire-de-la-radio'>Voir l&apos;histoire de la radio</Link>
                    </Button>
                </div>
            </div>
        </>
    )
}
export default HistoireRadioBannerComponent;